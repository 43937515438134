<template>
  <b-modal
    size="xl"
    centered
    lazy
    :title="'Importar endereços no armazém ' + warehouse.name"
    header-bg-variant="info"
    footer-border-variant="info"
    v-model="show"
    @hidden="onHidden"
    :hide-footer="true"
  >
    <b-container>

      <div class="row justify-content-between">

        <div class="col-sm-auto col-12">
          <button type="button" class="btn btn-secondary mobile-btn-100" @click="downloadTemplate()" :disabled="loading">
            <i class="fa fa-download"></i> Baixar template
          </button>
        </div>

        <div class="col-sm-auto col-12 mt-sm-0 mt-3">
          <input type="file" ref="importFileInput" style="display: none" @change="importFile">
          <button type="button" class="btn btn-success mobile-btn-100" :disabled="loading" @click="$refs.importFileInput.click()">
            <i class="fa fa-upload"></i> Importar
          </button>
        </div>

      </div>

      <div class="row justify-content-center mt-3" v-if="loading">
        <div class="col-12">
          <b-progress
            :value="sentLocationsCount"
            :max="totalLocations"
            show-value
            style="height:20px"
            class="bg-secondary"
          >
          </b-progress>
        </div>
        <div class="col-auto mt-3">
          <animation></animation>
        </div>
      </div>

      <div class="text-justify mt-3">
        <p>
          Utilize o botão "Baixar template" para baixar um template em CSV.
          Abra-o no excel ou outro editor de planilhas e, caso solicitado, selecione ";" (ponto e vírgula) como separador.
          Preencha os valores das colunas à partir da linha 2, sendo uma linha por endereço. <br>
          OBS: O preenchimento deve ser feito individualmente por armazém. Ou seja, um arquivo por armazém.
        </p>

        <div class="row font-weight-bold">
          <div class="border" :class="tableColumnSizes[0]">Coluna</div>
          <div class="border" :class="tableColumnSizes[1]">Descrição</div>
          <div class="border" :class="tableColumnSizes[2]">Obrigatório</div>
          <div class="border" :class="tableColumnSizes[3]">Exemplo</div>
        </div>

        <div class="row">
          <div class="border" :class="tableColumnSizes[0]">Nome</div>
          <div class="border" :class="tableColumnSizes[1]">Nome do endereço. Será usado como chave caso seja feita mais de uma importação.
              Ou seja, ao realizar uma importação num armazém que já tenha algum endereço com o mesmo nome de algum dos endereços da planilha
              esse endereço será substituído pelo da planilha.</div>
          <div class="border" :class="tableColumnSizes[2]">Sim</div>
          <div class="border" :class="tableColumnSizes[3]">R01 B02 A03</div>
        </div>

        <div class="row">
          <div class="border" :class="tableColumnSizes[0]">Agrupadores</div>
          <div class="border" :class="tableColumnSizes[1]">Nome dos agrupadores (Rua, Bloco, Andar, etc.) conforme o cadastro da empresa.</div>
          <div class="border" :class="tableColumnSizes[2]">Não</div>
          <div class="border" :class="tableColumnSizes[3]">A, B, 1, 2, 03, 04</div>
        </div>

        <div class="row">
          <div class="border" :class="tableColumnSizes[0]">Marca</div>
          <div class="border" :class="tableColumnSizes[1]">Código da marca do endereço. Se preechido somente itens dessa marca podem ser alocados nele.</div>
          <div class="border" :class="tableColumnSizes[2]">Não</div>
          <div class="border" :class="tableColumnSizes[3]">00001, 00002</div>
        </div>

        <div class="row">
          <div class="border" :class="tableColumnSizes[0]">Empresa</div>
          <div class="border" :class="tableColumnSizes[1]">Código da empresa do item. Obrigatório para endereços de tipo F (Fixo).</div>
          <div class="border" :class="tableColumnSizes[2]">*</div>
          <div class="border" :class="tableColumnSizes[3]">0001, 0002</div>
        </div>

        <div class="row">
          <div class="border" :class="tableColumnSizes[0]">Reduzido</div>
          <div class="border" :class="tableColumnSizes[1]">Código reduzido do item. Obrigatório para endereços de tipo F (Fixo).</div>
          <div class="border" :class="tableColumnSizes[2]">*</div>
          <div class="border" :class="tableColumnSizes[3]">000001, 000002</div>
        </div>

        <div class="row">
          <div class="border" :class="tableColumnSizes[0]">Tipo</div>
          <div class="border" :class="tableColumnSizes[1]">
            <label>Possui 3 possíveis valores e deve ser preenchido com a letra correspondente:</label>
            <ul>
              <li><b>F (Fixo)</b>: O endereço possui um item definido e o mesmo não é removido automaticamente</li>
              <li><b>D (Dinâmico)</b>: O endereço possui um item definido e o mesmo é automaticamente removido quando a quantidade chegar a 0</li>
              <li><b>I (Indefinido)</b>: O endereço não possui item definido podendo receber diversos e múltiplos itens</li>
            </ul>
            <p>Se o tipo não for informado será usado I (Indefinido).</p>
            <p>Se o tipo não for informado ou for informado como I (Indefinido), mas a empresa e o reduzido forem informados será usado F (Fixo) como tipo, pois endereços de item indefinido não podem possuir um item definido.</p>
          </div>
          <div class="border" :class="tableColumnSizes[2]">Não</div>
          <div class="border" :class="tableColumnSizes[3]">F, D, I</div>
        </div>

        <div class="row">
          <div class="border" :class="tableColumnSizes[0]">Qtd. Mínima</div>
          <div class="border" :class="tableColumnSizes[1]">Quantidade mínima do endereço. Não pode ser informado para endereços do tipo (I) Indefinido a não ser que a marca esteja preenchida.</div>
          <div class="border" :class="tableColumnSizes[2]">Não</div>
          <div class="border" :class="tableColumnSizes[3]">1, 2, 3</div>
        </div>

        <div class="row">
          <div class="border" :class="tableColumnSizes[0]">Qtd. Máxima</div>
          <div class="border" :class="tableColumnSizes[1]">Quantidade máxima do endereço. Não pode ser informado para endereços do tipo (I) Indefinido a não ser que a marca esteja preenchida.</div>
          <div class="border" :class="tableColumnSizes[2]">Não</div>
          <div class="border" :class="tableColumnSizes[3]">1000, 5000, 10000</div>
        </div>

        <p class="mt-3">Após finalizar o preenchimento clique em "Importar" e selecione o arquivo</p>
      </div>

      <confirmation-modal
        id="import-confirmation-modal"
        :msg="'Tem certeza de que deseja importar ' + totalLocations + ' endereços?'"
        :ok="confirmImport"
      ></confirmation-modal>

    </b-container>
  </b-modal>
</template>

<script>
import Animation from "@/components/loaders/animation";
import { httpClient } from "@/service";
import shared from '@/shared/shared';
import { ModelListSelect } from 'vue-search-select'
import ConfirmationModal from '@/components/common/confirmation-modal';

const chunkSize = 100;

export default {
  name: 'location-import-modal',
  components: {
    Animation,
    ModelListSelect,
    ConfirmationModal
  },

  props: {
    onHidden: {
      type: Function,
      default: () => {
        return;
      }
    },
    warehouse: {
      type: Object,
      require
    },
    successCallback: {
      type: Function,
      default: () => {
        return;
      }
    }
  },

  data() {
    return {
      show: false,
      loading: false,
      tableColumnSizes: [
        'col-lg-2 col-sm-3 col-12',
        'col-lg-6 col-sm-9 col-12',
        'col-lg-2 col-sm-3 col-12',
        'col-lg-2 col-sm-9 col-12'
      ],
      totalLocations: 0,
      lines: [],
      chunks: [],
      chunkIndex: 0,
      sentLocationsCount: 0
    }
  },

  mounted() {
  },

  computed: {
    importCompleted() {
      return this.sentLocationsCount >= this.totalLocations;
    }
  },

  methods: {
    showModal() {
      this.show = true;
    },

    hideModal() {
      this.show = false;
    },

    downloadTemplate() {
      this.loading = true;
      httpClient
      .get(`${process.env.VUE_APP_API_URL}locations/import/template/download`)
      .then(({ data }) => {
        this.loading = false;

        if (!data || !data.data) {
          this.$notify.textError('Ocorreu um erro')
          return
        }

        shared.downloadString(data.data, 'text/csv', 'template_importacao_enderecos.csv');
      })
      .catch((error) => {
        this.loading = false;
        if (error.message) {
          this.$notify.error(error)
        }
      });
    },

    importFile($event) {
      if (this.$refs.importFileInput
        && this.$refs.importFileInput.files
        && this.$refs.importFileInput.files.length) {
        let f = this.$refs.importFileInput.files[0];
        this.resetFileInput();

        if (!f.name || !f.name.endsWith('.csv')) {
          this.$notify.textError('Arquivo inválido. O tipo do arquivo deve ser CSV.');
          return;
        }

        this.loading = true;
        let reader = new FileReader();

        reader.onload = ((theFile) => {
          return (e) => {
            try {
              let content = e.target.result;

              if (content) {
                this.loading = false;
                this.lines = content.split('\n');

                if (!this.lines[0].length) {
                  this.lines.splice(0, 1);
                }

                if (!this.lines[this.lines.length - 1].length) {
                  this.lines.splice(this.lines.length - 1, 1);
                }

                this.totalLocations = this.lines.length - 1;
                this.$bvModal.show('import-confirmation-modal');
              } else {
                this.$notify.textError('Arquivo vazio');
                this.loading = false;
              }
            } catch (e) {
              this.loading = false;
              console.error(e);
              this.$notify.textError('Houve um erro ao importar o arquivo');
            }
          }
        })(f);

        reader.onerror = (error) => {
          this.loading = false;
          console.error(error);
          this.$notify.textError('Houve um erro ao importar o arquivo');
        }

        reader.readAsText(f);
      }
    },

    confirmImport() {
      this.chunks = [];
      this.chunkIndex = 0;
      this.sentLocationsCount = 0;

      for (let i = 0; i < this.lines.length; i += chunkSize) {
        let chunk = this.lines.slice(i, i + chunkSize);
        this.chunks.push(chunk);
      }

      console.log(this.chunks);
      this.sendNextChunk();
    },

    sendNextChunk() {
      this.loading = true;
      httpClient
      .post(
        `${process.env.VUE_APP_API_URL}locations/import/${this.warehouse.id}/${this.chunkIndex === 0}`,
        this.chunks[this.chunkIndex]
      ).then(({ data }) => {
        this.handleChunckSuccess(data);
      })
      .catch((error) => {
        this.loading = false;
        if (error.message) {
          this.$notify.error(error)
        } else {
          this.$notify.textError("Houve um erro ao importar o arquivo");
        }
      });
    },

    handleChunckSuccess(data) {
      this.loading = false;

      this.$notify.success(data.data.length + ' endereços importados com sucesso no armazém ' + this.warehouse.name);
      this.$notify.success('Página ' + (this.chunkIndex + 1) + ' importada com sucesso');
      this.chunkIndex++;
      this.sentLocationsCount += data.data.length;

      if (this.importCompleted) {
        this.$notify.success('Importação finalizada');

        if (this.successCallback) {
          this.successCallback();
        }

        this.hideModal();
      } else {
        this.sendNextChunk();
      }
    },

    resetFileInput() {
      this.$refs.importFileInput.value = null;
    }
  }
}
</script>

