<template>
  <div class="animated fadeIn">
    <div>
      <b-row>
        <div class="col-12">
          <b-card>
            <b-row class="mx-md-n3 mx-1">
              <div class="col-xl-6 col-12 mb-xl-0 mb-3">
                <b-input-group>
                  <b-form-input
                    v-model="filter"
                    @keyup.enter="onFiltering"
                    placeholder="Procurar por..."
                  />
                  <b-input-group-append>
                    <b-button size="md" text="Button" variant="outline-info" @click.passive="onFiltering">
                      <i class="cui-magnifying-glass"></i>
                    </b-button>
                  </b-input-group-append>

                  <div class="ml-2 pl-0">
                    <b-button
                      variant="secondary"
                      @click.prevent="openLocationBarcodeInput()"
                    >
                      <i class="fa fa-barcode"></i>
                    </b-button>
                  </div>
                </b-input-group>
              </div>
              <div class="col-xl-3 col-sm-6 col-12 mb-sm-0 mb-3">
                <b-button variant="secondary" @click="openBarcodesPrintModal()" class="btn-block">Imprimir códigos de barra</b-button>
              </div>
              <div class="col-xl-3 col-sm-6 col-12">
                <b-link to="/warehouse/allocations/new" class="btn btn-primary btn-block">Novo Endereço</b-link>
              </div>
              <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                <div class="row align-items-center">
                  <div class="col-sm-auto col-12 mb-sm-0 mb-1">
                    <label class="mb-0">Item:</label>
                  </div>
                  <div class="col">
                    <div v-if="loadingItems">
                      <loading-animation />
                    </div>
                    <model-list-select
                      v-else
                      :list="items"
                      option-value="id"
                      option-text="label"
                      v-model="selectedItem"
                      placeholder="Digite para pesquisar..."
                      @searchchange="searchItem"
                      @keyup.enter="onFiltering"
                      :filterPredicate="alwaysTrueFilterPredicate"
                    />
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                <model-list-select
                  :list="types"
                  option-value="value"
                  option-text="label"
                  v-model="selectedType"
                  placeholder="Selecione o Tipo"
                />
              </div>
              <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                <model-list-select
                  :list="stockOptions"
                  option-value="value"
                  option-text="label"
                  v-model="stockOption"
                  placeholder="Selecione o filtro por estoque"
                />
              </div>
              <div class="col-12 mt-3">
                <div v-if="loadingLocationGroupers" class="mb-5 pb-5">
                  <loading-animation />
                </div>

                <div v-else-if="locationGroupers" class="row">
                  <div v-for="(grouperId, index) in Object.keys(locationGroups)" :key="index" class="col-xl-2 col-sm-3 col-6">
                    <div class="mb-3">
                      <label>{{locationGroups[grouperId].locationGrouper.name}}</label>
                      <b-form-input :list="'groups-' + grouperId" v-model="locationGroups[grouperId].name"
                        @keyup="locationGroupSearchChanged(locationGroups[grouperId])"></b-form-input>
                      <datalist v-if="locationGroupsSuggestions[grouperId]" :id="'groups-' + grouperId">
                        <option v-for="(suggestion, index) in locationGroupsSuggestions[grouperId]" :key="index"
                          :value="suggestion.name"></option>
                      </datalist>
                    </div>
                  </div>
                </div>
              </div>
            </b-row>
          </b-card>
        </div>
      </b-row>

      <b-row>
        <div class="col-12">
          <b-tabs
            lazy
          >
            <b-tab active class="px-md-3 px-0">
              <template slot="title">
                <p> Endereços </p>
              </template>

              <allocations-table
                id="pickings"
                resourceName="allocationsResourceList"
                class="mb-3"
                :url="pickings"
                :fields='fieldsPickings'
                :editAction='update'
                :deleteAction='remove'
                :locationGroupers="locationGroupers"
                ref="allocationsTable">
              </allocations-table>

              <b-button v-if="isUserManager" variant="warning" @click="openLocationImportModal" class="mt-0">Importar endereços</b-button>
            </b-tab>

            <!-- <b-tab>
              <template slot="title">
                <p> Reservas </p>
              </template>

              <reserves-table
                id="reservers"
                resourceName="allocationsResourceList"
                :url="reserves"
                :fields='fieldsReserve'
                :editAction='update'
                :deleteAction='remove' />
            </b-tab> -->
          </b-tabs>
        </div>
      </b-row>
    </div>

    <b-modal
      centered
      lazy
      title="Cuidado com esta Ação!"
      header-bg-variant="warning"
      footer-border-variant="warning"
      @ok.passive="executeRemote"
      v-model="modalShow">
      <div ref="message"></div>

      <p class="text-center">Clique em OK para confirmar ou Cancelar para desistir da operação</p>
    </b-modal>

    <b-modal
      centered
      size="md"
      lazy
      title="Imprimir códigos de barra"
      header-bg-variant="info"
      footer-border-variant="info"
      v-model="showBarcodesPrintModal">
      <b-container>
        <div v-if="loadingBarcodePDF">
          <loading-animation />
        </div>

        <div v-else>
          <b-form-group>
            <b-form-radio v-model="barcodePrintSelectedOption" name="barcodePrintSelectedOptions" value="1"
              :disabled="!selectedLocations || !selectedLocations.length">
              Imprimir os códigos de barra dos endereços selecionados
            </b-form-radio>

            <div v-if="!selectedLocations || !selectedLocations.length" class="row justify-content-center">
              <div class="col-auto">
                <small class="text-danger">
                  Nenhum endereço selecionado
                </small>
              </div>
            </div>

            <b-form-radio v-model="barcodePrintSelectedOption" name="barcodePrintSelectedOptions" value="2">
              Imprimir os códigos de barra de todos os endereços do filtro atual
            </b-form-radio>
            <b-form-radio v-model="barcodePrintSelectedOption" name="barcodePrintSelectedOptions" value="3" class="my-2">
              Imprimir os códigos de barra de todos os endereços do armazém {{warehouse ? warehouse.name : ''}}
            </b-form-radio>
            <b-form-radio v-model="barcodePrintSelectedOption" name="barcodePrintSelectedOptions" value="4">
              Imprimir os códigos de barra dos endereços de todos os armazéns
            </b-form-radio>

          </b-form-group>

          <b-form-group
            label="Modelo de etiqueta"
          >
            <model-list-select
              :list="barcodePrintModels"
              option-value="value"
              option-text="label"
              v-model="barcodePrintModel"
            />
          </b-form-group>
        </div>
      </b-container>

      <template slot="modal-footer" slot-scope="{ ok, cancel }">
        <b-button variant="secondary" @click="closeBarcodePrintModal">Cancelar</b-button>
        <b-button variant="primary" @click="printBarcodes" :disabled="!isBarcodePrintSelectedOptionValid || loadingBarcodePDF">
          Gerar PDF
        </b-button>
      </template>
    </b-modal>

    <location-import-modal
      ref="locationImportModal"
      v-if="isUserManager"
      :warehouse="warehouse"
      :successCallback="onFiltering"
    ></location-import-modal>

    <barcode-input-modal
      id="location-barcode-input-modal"
      :ok="saveBarCode"
    ></barcode-input-modal>

  </div>
</template>

<script>
  import { LoadingSpinner } from '@/components/loaders'
  import { allocation as service } from '@/service'
  import AllocationsTable from '@/components/tables/pickings-table'
  import ReservesTable from '@/components/tables/reserves-table'
  import Axios from 'axios'
  import { LoadingAnimation } from '@/components/loaders'
  import shared from '@/shared/shared'
  import { ModelListSelect } from 'vue-search-select'
  import { httpClient } from '@/service'
  import { auth } from "@/service";
  import LocationImportModal from '@/components/modals/location-import-modal';
  import BarcodeInputModal from "@/components/common/barcode-input-modal";

  export default {
    name: 'index',
    components: {
      ReservesTable,
      AllocationsTable,
      LoadingSpinner,
      LoadingAnimation,
      ModelListSelect,
      LocationImportModal,
      BarcodeInputModal
    },

    data () {
      return {
        modalShow: false,
        modalMessage: '',
        allocation: null,
        pickings: process.env.VUE_APP_API_URL + 'locations',
        //reserves: '',
        fieldsPickings: [
          { key: 'name', label: 'Nome', sortable: true },
          { key: 'address', label: 'Endereço', sortable: true },
          { key: 'quantity', label: 'Quantidade' },
          { key: 'quantityMax', label: 'Capacidade' },
          { key: 'quantityMin', label: 'Mínimo' },
          /*{ key: 'reserve', label: 'Reserva', sortable: true  },
          { key: 'itemAllocated', label: 'Item ' },*/
          { key: 'item.description', label: 'Item' },
          { key: 'actions', label: 'Ações' },
        ],
        /*fieldsReserve: [
          { key: 'name', label: 'Nome', sortable: true },
          { key: 'address', label: 'Endereçamento', sortable: true },
          { key: 'quantity', label: 'Quantidade' },
          { key: 'quantityMax', label: 'Capacidade' },
          { key: 'quantityMin', label: 'Mínimo' },
          { key: 'itemAllocated', label: 'Item ' },
          { key: 'actions', label: 'Ações' },
        ],*/
        filter: null,
        showBarcodesPrintModal: false,
        warehouse: {},
        barcodePrintSelectedOption: 1,
        selectedLocations: [],
        loadingBarcodePDF: false,
        items: [{id: null, label: 'Todos'}],
        loadingItems: false,
        selectedItem: {},
        selectedType: {},
        types: [
          { label: 'Todos', value: null },
          { label: 'Item fixo', value: 'F' },
          { label: 'Item dinâmico', value: 'D' },
          { label: 'Item indefinido', value: 'U' },
        ],
        stockOptions: [
          { label: 'Ambos', value: 0 },
          { label: 'Apenas com estoque', value: 1 },
          { label: 'Apenas sem estoque', value: 2 }
        ],
        stockOption: {},
        loadingLocationGroupers: false,
        locationGroupers: [],
        locationGroupsSuggestions: {},
        loadingLocationGroupSuggestions: {},
        locationGroupSuggestionSearchTimeout: null,
        locationGroups: {},
        barcodePrintModel: null,
        barcodePrintModels: [],
        config: {},
        authUser: null,
        isUserManager: false
      }
    },

    mounted: async function () {
      this.authUser = shared.getLocalStorageObj("auth/user");
      this.filter = shared.getLocalStorageString('allocation/allocationsFilter');
      //const { allPickings, allReserve } = await localStorage.getItem('endpoints/allocation');
      this.warehouse = await shared.getLocalStorageObj('warehouse/warehouse');

      this.$store.commit('app/SET_CURRENT_PAGE', { title:`Endereços de ${this.warehouse.name}`, size: 6 })
      /*this.pickings  = allPickings.href.replace('{UUID}', id)
      this.reserves = allReserve.href*/

      this.selectedItem = shared.getLocalStorageObj('allocation/filterItem');
      if (this.selectedItem && this.selectedItem.id && Object.keys(this.selectedItem).length) {
        this.items.push(this.selectedItem);
        this.setItemsLabel();
      }

      this.selectedType = shared.getLocalStorageObj('allocation/filterType');
      this.locationGroups = shared.getLocalStorageObj('allocation/filterLocationGroups');
      this.loadFilterStockOption();

      this.getLocationGroupers();

      this.config = shared.getConfig();
      this.barcodePrintModel = this.config.printConfig.locationModel;

      this.$nextTick(() => {
        this.getItemLotPrintModels();
      });

      this.setIsUserManager();
    },

    methods: {
      loadFilterStockOption() {
        let temp = shared.getLocalStorageString('allocation/filterStockOption');

        if (temp) {
          try {
            temp = Number(temp);

            if (!isNaN(temp)) {
              let option = this.stockOptions.find(o => o.value === temp);

              if (option) {
                this.stockOption = option;
              }
            }
          } catch (e) {
            console.error(e);
          }
        }
      },

      async getItemLotPrintModels() {
        this.barcodePrintModels = await shared.getItemLotPrintModels(httpClient, { locationOnly: true });
      },

      update: async function (allocation) {
        this.$router.push('/warehouse/allocations/edit/' + allocation.id);
      },

      remove: async function (allocation) {
        const modalMessage =
          `<p class="text-center ">
            Você está prestes a excluir o Endereço: <strong>${allocation.name}</strong>.
            Você tem certeza desta ação? Esta ação
            <strong>NÃO</strong> poderá ser revertida
           </p>`

        this.modalShow = true
        const { message } = await this.$refs
        message.innerHTML = modalMessage
        this.allocation = allocation
      },

      executeRemote: async function () {
        try {
          await service.delete(this.allocation.id)
          this.$notify.success("Removido com sucesso")
          this.onFiltering(this.filter);
        } catch (error) {
          this.$notify.error(error)
        }
      },

      async onFiltering() {
        shared.setLocalStorageString('allocation/allocationsFilter', this.filter);
        shared.setLocalStorageObj('allocation/filterLocationGroups', shared.getLocationGroupsWithJustNames(this.locationGroups));

        this.$refs.allocationsTable.filter(
          this.filter, this.selectedItem, this.selectedType.value, this.stockOption ? this.stockOption.value : null, this.locationGroups, null
        );
      },

      openBarcodesPrintModal() {
        this.selectedLocations = this.$refs.allocationsTable.getSelectedLocations();
        this.barcodePrintSelectedOption = 1;
        this.showBarcodesPrintModal = true;
      },

      printBarcodes() {
        let barcodeModels = [];
        let warehouseId = null;
        let condition = null;
        this.barcodePrintSelectedOption = Number(this.barcodePrintSelectedOption);

        switch (this.barcodePrintSelectedOption) {
          case 1: {
            if (!this.selectedLocations || !this.selectedLocations.length) {
              this.$notify.textError('Nenhum endereço selecionado');
              return;
            }

            this.selectedLocations.forEach(location => {
              if (location.item && location.item.defaultLocation) {
                delete location.item.defaultLocation;
              }

              barcodeModels.push({
                barcode: location.barcode,
                location: location,
                quantity: 1,
                meta: [
                  location.name,
                  location.address
                ]
              });
            });

            break;
          }
          case 2: {
            if (!this.warehouse || !this.warehouse.id) {
              this.$notify.textError('Nenhum armazém selecionado');
              return;
            }

            condition = this.$refs.allocationsTable.getCondition();
            break;
          }

          case 3: {
            if (!this.warehouse || !this.warehouse.id) {
              this.$notify.textError('Nenhum armazém selecionado');
              return;
            }

            warehouseId = this.warehouse.id;
            break;
          }
        }

        let url = process.env.VUE_APP_API_URL + 'locations/barcode/pdf';
        if (warehouseId) {
          url += '?warehouseId=' + warehouseId + '&';
        } else {
          url += '?';
        }

        this.loadingBarcodePDF = true;
        this.config = shared.setModelAndTemplateIdFromSelectedBarcodeModel(this.config, this.barcodePrintModel);

        Axios({
          url: url,
          method: 'POST',
          data: {
            models: barcodeModels,
            options: this.config.printConfig,
            customCondition: condition
          },
          responseType: 'blob',
          headers: auth.getAxiosHeaders()
        }).then((response) => {
          this.loadingBarcodePDF = false;
          if (response && response.data && response.data.size) {
            let file = new Blob([response.data], { type: 'application/pdf' });
            let url = window.URL.createObjectURL(file);
            window.open(url);
            this.showBarcodesPrintModal = false;
          } else {
            this.$notify.textError('Houve um erro ao gerar o PDF. Por favor tente novamente');
          }
        }).catch((error) => {
          this.loadingBarcodePDF = false;

          if (error.response && error.response.headers && error.response.headers.errormessage) {
            this.$notify.textError(error.response.headers.errormessage);
          } else if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao gerar o PDF. Por favor tente novamente');
          }
        });
      },

      closeBarcodePrintModal() {
        this.showBarcodesPrintModal = false;
      },

      async searchItem (searchText) {
        if (!this.searchItemInProcess && (
          (searchText && searchText.length) || !this.selectedItem || Object.keys(this.selectedItem).length == 0
        )) {
          if (this.itemSearchTimeout) {
            clearTimeout(this.itemSearchTimeout);
          }

          this.itemSearchTimeout = setTimeout(() => {
            this.searchItemInProcess = true

            if (searchText && searchText.length) {
              httpClient
              .post(`${process.env.VUE_APP_API_URL}items/condition?page=${0}&size=${10}`, {
                conditions: [
                  {
                    logicalOperator: 'OR',
                    conditions: [
                      {
                        field: 'description',
                        conditionalOperator: 'LIKE_START',
                        value: searchText
                      },
                      {
                        field: 'ref',
                        conditionalOperator: 'LIKE_END',
                        value: shared.mountReduzidoFilter(searchText)
                      },
                      {
                        field: 'sku',
                        conditionalOperator: 'LIKE',
                        value: searchText
                      },
                      {
                        field: 'gtin',
                        conditionalOperator: 'LIKE',
                        value: searchText
                      }
                    ]
                  }
                ]
              })
              .then(({ data }) => {
                this.items = this.handleData(data);
                this.setItemsLabel(searchText);
                this.items.unshift({id: null, label: 'Todos'});
              })
              .catch((error) => {
                this.searchItemInProcess = false
                if (error.message) {
                  this.$notify.error(error)
                }
              });
            } else {
              httpClient
              .get(`${process.env.VUE_APP_API_URL}items/?page=${0}&size=${10}`)
              .then(({ data }) => {
                this.items = this.handleData(data);
                this.setItemsLabel(searchText);
                this.items.unshift({id: null, label: 'Todos'});
              })
              .catch((error) => {
                this.searchItemInProcess = false
                if (error.message) {
                  this.$notify.error(error)
                }
              });
            }
          }, 500);
        }
      },

      handleData(data) {
        this.searchItemInProcess = false
        this.totalRows = data.data.totalElements
        const items = data.data.content

        return items
      },

      getItemReduzidoFromRef(ref) {
        return shared.getItemReduzidoFromRef(ref);
      },

      setItemsLabel(searchText = null) {
        if (this.items && this.items.length) {
          this.items.forEach(item => {
            if (item.id) {
              item.label = this.getItemReduzidoFromRef(item.ref) + " - " + item.description;
            }
            if(searchText){
              if(item.sku && (item.sku.indexOf(searchText) >= 0)){
                item.label += ' - Ref: ' + item.sku;
              }
              if(item.gtin && (item.gtin.indexOf(searchText) >= 0)){
                item.label += ' - Cód barras: ' + item.gtin;
              }
            }
          });
        }
      },

      alwaysTrueFilterPredicate() {
        return true;
      },

      getLocationGroupers() {
        this.loadingLocationGroupers = true;
        httpClient.get(`${process.env.VUE_APP_API_URL}location-groupers?page=0&size=100`)
        .then(data => {
          this.loadingLocationGroupers = false;
          this.locationGroupers = data.data.data.content;
          this.addMissingLocationGroups();
        }).catch(error => {
          this.loadingLocationGroupers = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError("Houve um erro ao buscar os agrupadores");
          }

          this.onFiltering();
        });
      },

      addMissingLocationGroups() {
        this.locationGroupers = shared.sortLocationGroupers(this.locationGroupers);

        this.locationGroupsSuggestions = {};
        this.loadingLocationGroupSuggestions = {};

        if (!this.locationGroups) {
          this.locationGroups = {};
        }

        this.locationGroupers.forEach(grouper => {
          this.locationGroupsSuggestions[grouper.id] = [];
          this.loadingLocationGroupSuggestions[grouper.id] = false;

          if (!this.locationGroups[grouper.id]) {
            this.locationGroups[grouper.id] = {
              name: null,
              locationGrouper: grouper
            };
          } else {
            this.locationGroups[grouper.id].locationGrouper = grouper;
          }
        });

        this.onFiltering();
      },

      locationGroupSearchChanged(group) {
        if (!this.loadingLocationGroupSuggestions[group.locationGrouper.id] && group.name && group.name.length) {
          if (this.locationGroupSuggestionSearchTimeout) {
            clearTimeout(this.locationGroupSuggestionSearchTimeout);
          }

          this.locationGroupSuggestionSearchTimeout = setTimeout(() => {
            this.loadingLocationGroupSuggestions[group.locationGrouper.id] = true;
            httpClient.post(
              `${process.env.VUE_APP_API_URL}location-groups/select-and-where?page=${this.currentPage - 1}&size=${this.perPage}`, {
              select: ['id', 'name'],
              where: {
                conditions: [
                  {
                    field: 'locationGrouper.id',
                    value: group.locationGrouper.id
                  },
                  {
                    field: 'name',
                    conditionalOperator: 'LIKE_START',
                    value: group.name
                  }
                ]
              }
            }).then(({ data }) => {
              this.loadingLocationGroupSuggestions[group.locationGrouper.id] = false;
              this.locationGroupsSuggestions[group.locationGrouper.id] = data.data.content;
              let groupsWithoutAccents = [];

              this.locationGroupsSuggestions[group.locationGrouper.id].forEach(g => {
                let nameWithoutAccents = g.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

                if (g.name !== nameWithoutAccents) {
                  let newGroup = JSON.parse(JSON.stringify(g));
                  newGroup.name = nameWithoutAccents;
                  groupsWithoutAccents.push(newGroup);
                }
              });

              this.locationGroupsSuggestions[group.locationGrouper.id].push(...groupsWithoutAccents);
              let locationGroupsSuggestions = this.locationGroupsSuggestions;
              this.locationGroupsSuggestions = {};
              this.locationGroupsSuggestions = locationGroupsSuggestions;
            })
            .catch((error) => {
              this.loadingLocationGroupSuggestions[group.locationGrouper.id] = false;
              if (error.message) {
                this.$notify.error(error)
              }
              this.locationGroupsSuggestions[group.locationGrouper.id] = [];
            });
          }, 200);
        }
      },

      openLocationImportModal() {
        this.$nextTick(() => {
          this.$refs.locationImportModal.showModal();
        });
      },

      setIsUserManager() {
        if (
          this.authUser &&
          this.authUser.profile &&
          this.authUser.profile.modules
        ) {
          let profileModule = this.authUser.profile.modules.find(
            (pm) => {
              return (
                pm.name && pm.name.toString().toUpperCase() == "ITEMS"
              );
            }
          );

          this.isUserManager =
            profileModule && profileModule.canExecute;
        }
      },
      openLocationBarcodeInput() {
        this.$bvModal.show("location-barcode-input-modal");
      },

      saveBarCode(barcode) {
        this.filter = barcode
        this.onFiltering(this.filter);
        this.$bvModal.hide("location-barcode-input-modal");
      }
    },

    computed: {
      isBarcodePrintSelectedOptionValid() {
        return this.barcodePrintSelectedOption != 1 || (this.selectedLocations && this.selectedLocations.length);
      }
    },

    watch: {
      'selectedItem': function(newVal, oldVal) {
        shared.setLocalStorageObj('allocation/filterItem', this.selectedItem);

        if (!shared.entitiesAreEqual(newVal, oldVal)) {
          this.onFiltering();
        }
      },
      'selectedType': function(newVal, oldVal) {
        shared.setLocalStorageObj('allocation/filterType', this.selectedType);

        if (!shared.entitiesAreEqual(newVal, oldVal, 'value')) {
          this.onFiltering();
        }
      },
      'stockOption': function(newVal, oldVal) {
        shared.setLocalStorageString('allocation/filterStockOption', this.stockOption ? String(this.stockOption.value) : "");
        this.onFiltering();
      }
    }
  }
</script>

<style scoped>
  table#pickings .flip-list-move {
    transition: transform 1s;
  }
</style>
