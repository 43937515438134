<template>
    <div class="card">
      <div class="card-body p-0">
        <div v-if="loadingRows" class="mb-5 pb-5">
          <loading-animation />
        </div>

        <div v-if="!loadingRows && !getItems().length" class="mb-3">
          <div class="row bg-light mx-0 py-3">
            <div class="col text-center">
              Nenhum Endereço encontrado
            </div>
          </div>
        </div>

        <div v-if="!loadingRows && getItems().length" class="mb-3">
          <div class="row mx-0 justify-content-end">
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12">
              <div class="row align-items-center">
                  <div class="col-auto pr-1">
                      <b-form-checkbox v-model="allPrintCheckboxesMarked" @change="markAllPrintCheckboxes" class="my-2">
                          <small v-if="allPrintCheckboxesMarked" class="font-weight-bold">Desmarcar todos</small>
                          <small v-else class="font-weight-bold">Marcar todos </small>
                      </b-form-checkbox>
                  </div>

                  <div class="col-auto pl-0">
                      <button id="popover-target-1" class="btn btn-link p-0">
                          <i class="fa fa-question-circle"></i>
                      </button>
                      <b-popover target="popover-target-1" triggers="hover" placement="top">
                          <span>OBS: Aplicável apenas à página atual</span>
                      </b-popover>
                  </div>
              </div>
          </div>
          </div>

          <div v-for="(item, index) in getItems()" class="row py-3 border mx-0" :key="index" :class="{ 'bg-light': index % 2 != 0 }">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="row">
                <div class="col-xl-12 col-lg-6 col-md-6 col-sm-6 col-12">
                  <span class="font-weight-bold">Nome: </span>{{item.name}}
                </div>
                <div class="col-xl-12 col-lg-6 col-md-6 col-sm-6 col-12">
                  <span class="font-weight-bold">Tipo: </span>{{getLocationTypeLabel(item.type)}}
                </div>
                <div v-if="item.type !== 'U'" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <span class="font-weight-bold">Item: </span>
                  {{item.item ? (getItemReduzidoFromRef(item.item.ref) + " - " + item.item.description) : ''}}
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <span class="font-weight-bold">Código de barras: </span>{{item.barcode}}
                </div>
                <div v-if="item.locationGroups && item.locationGroups.length" class="col-12">
                  <div class="row">
                    <div v-for="(group, index) in item.locationGroups" :key="index" class="col-xl-auto col-sm-3 col-6">
                      <span v-if="group.locationGrouper" class="font-weight-bold">{{group.locationGrouper.name}}: </span>{{group.name}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-8 col-md-8 col-sm-8 col-12 mt-xl-0 mt-sm-2 mt-0">
              <div class="row">
                <div class="col-xl-12 col-lg-4 col-md-4 col-sm-4 col-4">
                  <span class="font-weight-bold">Quantidade: </span>{{item.quantity}}
                </div>
                <div v-if="item.type !== 'U'" class="col-xl-12 col-lg-4 col-md-4 col-sm-4 col-4">
                  <span class="font-weight-bold">Capacidade: </span>{{item.quantityMax}}
                </div>
                <div v-if="item.type !== 'U'" class="col-xl-12 col-lg-4 col-md-4 col-sm-4 col-4">
                  <span class="font-weight-bold">Mínimo: </span>{{item.quantityMin}}
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12 mt-xl-0 mt-2">
              <div class="row justify-content-start">
                <div class="col-sm-auto col-6 pr-0">
                  <b-button v-show="editAction" size="sm" @click.passive="editAction(item)" class="mobile-btn-100">
                    <i class="fa fa-edit"></i> Editar
                  </b-button>
                </div>
                <div class="col-sm-auto col-6">
                  <b-button v-show="deleteAction" variant="danger" size="sm" @click.passive="deleteAction(item)"
                        class="mobile-btn-100" :disabled="item.quantity > 0">
                    <i class="fa fa-trash"></i> Excluir
                  </b-button>
                </div>
                <div v-if="item.barcode && item.barcode.length" class="col-12 mt-3">
                  <b-form-checkbox v-model="item.printBarcode">
                    <small class="font-weight-bold">Imprimir Código de barras</small>
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <nav>
          <b-pagination
            v-if="totalRows"
            align="center"
            :limit="10"
            :total-rows="totalRows"
            :per-page="perPage"
            prev-text="Anterior"
            next-text="Próximo"
            v-model="currentPage"
            @change="pageChanged"
            :disabled="loadingRows"/>
        </nav>
      </div>
    </div>
</template>

<script>
  import { LoadingAnimation } from '@/components/loaders'
  import { httpClient } from '@/service'
  import shared from '@/shared/shared'

  export default {
    name: 'pickings-table',
    components: { LoadingAnimation },
    props: {
      id: {
        type: String,
        require
      },
      fields: {
        type: [Array, Object],
        default: () => [],
      },
      editAction: {
        type: Function,
      },
      deleteAction: {
        type: Function,
      },
      url: {
        type: String,
      },
      resourceName: {
        type: String,
      },
      locationGroupers: {
        type: Array,
        require
      }
    },

    data: () => {
      return {
        currentPage: 1,
        perPage: 10,
        totalRows: null,
        items: [],
        stockLocale: null,
        loadingRows: false,
        lastFilter: '',
        allPrintCheckboxesMarked: false,
        lastItem: {},
        lastType: null,
        lastStockOption: false,
        lastLocationGroups: {}
      }
    },

    created: function() {
      this.stockLocale = shared.getLocalStorageObj('warehouse/warehouse');
      this.currentPage = Number(shared.getLocalStorageString('location/currentPage'))

      if (!this.currentPage) {
        this.currentPage = 1;
      }
    },

    methods: {
      getItems() {
        return this.items;
      },

      getPage(context) {
        if (this.loadingRows) {
          return;
        }

        shared.setLocalStorageString('location/currentPage', context.currentPage);

        let condition = this.getCondition(context);

        this.loadingRows = true;
        httpClient
        .post(`${context.apiUrl}/condition/with-quantity?page=${context.currentPage - 1}&size=${context.perPage}&sort=name,asc`, condition)
        .then(({ data }) => {
          this.handleData(data);
        })
        .catch((error) => {
          this.loadingRows = false;
          if (error.message) {
            this.$notify.error(error)
          }

          this.items = [];
        });
      },

      pageChanged(page) {
        this.getPage({
          apiUrl: this.url,
          currentPage: page,
          perPage: this.perPage,
          filter: this.lastFilter,
          item: this.lastItem,
          type: this.lastType,
          stockOption: this.lastStockOption,
          locationGroups: this.lastLocationGroups
        })
      },

      handleData(data) {
        this.loadingRows = false;
        this.totalRows = data.data.totalElements
        this.items = data.data.content;
        this.items.forEach(location => {
          location.locationGroups = shared.sortLocationGroups(location.locationGroups, this.locationGroupers);
        });

        this.$root.$emit('bv::refresh::table', this.id)

        return this.items;
      },

      filter(filter, item, type, stockOption, locationGroups, page) {
        if (filter !== this.lastFilter || item.id !== this.lastItem.id || type != this.lastType || stockOption !== this.lastStockOption) {
          page = 1;
        } else {
          for (let grouperId in locationGroups) {
            if (!this.lastLocationGroups[grouperId] || this.lastLocationGroups[grouperId].name !== locationGroups[grouperId].name) {
              page = 1;
              break;
            }
          }
        }

        this.lastFilter = filter;
        this.lastItem = item;
        this.lastType = type;
        this.lastStockOption = stockOption;
        this.lastLocationGroups = JSON.parse(JSON.stringify(shared.getLocationGroupsWithJustNames(locationGroups)));

        if (page) {
          this.currentPage = page;
        }

        this.getPage({
          apiUrl: this.url,
          currentPage: this.currentPage,
          perPage: this.perPage,
          filter: filter,
          item: item,
          type: type,
          stockOption: stockOption,
          locationGroups: locationGroups
        })
      },

      markAllPrintCheckboxes() {
        if (this.items) {
          this.items.forEach(item => {
            if (item.barcode && item.barcode.length) {
              item.printBarcode = this.allPrintCheckboxesMarked;
            } else {
              item.printBarcode = false;
            }
          });

          let items = this.items;
          this.items = [];
          this.items = items;
        }
      },

      getSelectedLocations() {
        let selectedLocations = [];

        if (this.items) {
          this.items.forEach(item => {
            if (item.printBarcode) {
              selectedLocations.push(item);
            }
          });
        }

        return selectedLocations;
      },

      getItemReduzidoFromRef(ref) {
        return shared.getItemReduzidoFromRef(ref);
      },

      getLocationTypeLabel(type) {
        return shared.getLocationTypeLabel(type);
      },
      
      getCondition(context) {
        if (!context) {
          context = {
            apiUrl: this.url,
            currentPage: this.currentPage,
            perPage: this.perPage,
            filter: this.lastFilter,
            item: this.lastItem,
            type: this.lastType,
            stockOption: this.lastStockOption,
            locationGroups: this.lastLocationGroups
          }
        }
        let conditions = [{
          field: 'stockLocale.id',
          value: this.stockLocale.id
        }];
        let having;
        let distinct = true;
        let groupBy;

        if (context.filter && context.filter.length) {
          conditions.push({
              logicalOperator: 'OR',
              conditions: [{
              field: 'name',
              conditionalOperator: 'LIKE',
              value: context.filter
            },
            {
              field: 'barcode',
              conditionalOperator: 'EQUALS',
              value: context.filter
            }]
          });
        }

        if (context.item && context.item.id) {
          conditions.push({
            logicalOperator: 'OR',
            conditions: [
              {
                joinType: 'LEFT',
                field: 'item.id',
                value: context.item.id
              },
              {
                conditions: [
                  {
                    joinType: 'LEFT',
                    field: 'balances.entries',
                    conditionalOperator: 'GREATER_THAN',
                    expressionValue: 'balances.exits'
                  },
                  {
                    joinType: 'LEFT',
                    field: 'balances.itemLot.item.id',
                    value: context.item.id
                  }
                ]
              }
            ]
          });
        }

        if (context.type && context.type !== "null") {
          conditions.push({
            field: 'type',
            value: context.type
          });
        }

        if (context.stockOption) {
          if (context.stockOption === 1) {
            conditions.push({
              field: 'balances.entries',
              conditionalOperator: 'GREATER_THAN',
              expressionValue: 'balances.exits'
            });
          } else if (context.stockOption === 2) {
            having = {
              logicalOperator: 'OR',
              conditions: [
                {
                  field: 'diff(sum(balances.entries), sum(balances.exits))',
                  conditionalOperator: 'IS_NULL',
                  joinType: 'LEFT'
                },
                {
                  field: 'sum(balances.entries)',
                  conditionalOperator: 'LESS_THAN_OR_EQUAL_TO',
                  expressionValue: 'sum(balances.exits)',
                  joinType: 'LEFT'
                }
              ]
            };
            distinct = false;
            groupBy = ["id"]
          }
        }

        if (context.locationGroups) {
          let i = 0;
          for (let grouperId in context.locationGroups) {
            if (context.locationGroups[grouperId].name && context.locationGroups[grouperId].name.length) {
              conditions.push(...[
                {
                  field: 'locationGroups.name',
                  value: context.locationGroups[grouperId].name,
                  fieldJoins: [{
                    field: 'locationGroups',
                    alias: 'lg' + i
                  }]
                },
                {
                  field: 'locationGroups.locationGrouper.id',
                  value: grouperId,
                  fieldJoins: [
                    {
                      field: 'locationGroups',
                      alias: 'lg' + i
                    },
                    {
                      sourceField: 'locationGroups',
                      field: 'locationGrouper',
                      alias: 'g' + i
                    },
                  ]
                }
              ]);

              i++;
            }
          }
        }

        return {
          conditions: conditions,
          groupBy: groupBy,
          distinct: distinct,
          having: having
        }
      }
    },
  }
</script>

<style scoped>
  .items, .reserves {
    cursor: pointer;
  }
</style>
